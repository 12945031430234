import {useEffect} from 'react'
import {Provider} from "react-redux"
import {persistStore} from 'redux-persist'
import {PersistGate as PersistGateClient} from 'redux-persist/integration/react'
import {PersistGateServer} from '../store'
import {isServer} from '../env'
import {useStore} from '../store'
import {useRouter} from "next/router"
import '../styles/main.scss';

import NextNprogress from "nextjs-progressbar";
import 'dayjs/locale/ru'

import {PageTransition} from 'next-page-transitions'

import TagManager from "react-gtm-module"

export default function App({Component, pageProps}) {
    const store = useStore(pageProps.initialReduxState)
    const persistor = persistStore(store)
    const PersistGate = isServer ? PersistGateServer : PersistGateClient
    const router = useRouter()

    const spring = {
        type: "spring",
        damping: 20,
        stiffness: 100,
        when: "afterChildren"
    };

    const TIMEOUT = 400

    useEffect(() => {
        document.querySelectorAll('small').forEach(small => small.style.opacity = "1")
    }, [])

    useEffect(() => {
        const tagManagerArgs = {
          gtmId: process.env.NEXT_PUBLIC_GTM_KEY,
        }

        if(process.env.NEXT_PUBLIC_ENABLE_GTM === 'true' )
            TagManager.initialize(tagManagerArgs)

        router.events.on('routeChangeStart', () => {
            process.env.NEXT_PUBLIC_ENABLE_CALLTOUCH === 'true' && addCalltouchScript(false)
        })
        router.events.on('routeChangeComplete', () => {
            window.scrollTo({
                top: 0,
                behavior: "instant"
            })
            process.env.NEXT_PUBLIC_ENABLE_CALLTOUCH === 'true' && addCalltouchScript(true)
        })
        process.env.NEXT_PUBLIC_ENABLE_CALLTOUCH === 'true' && addCalltouchScript(true)
    }, [])

    const addCalltouchScript = (bool) => {
        const body = document.querySelector('body')
        if (body) {
            if (bool) {
                const script = document.createElement('script')
                script.id = 'calltouchId'
                script.async = true
                script.src = '/calltouch.js'
                body.append(script)
            } else {
                const allScripts = document.querySelector('head').querySelectorAll('script')
                allScripts.forEach(script => {
                    const src = script.getAttribute('src')
                    if(src?.includes('mod.calltouch')){
                        script.remove()
                    }
                })
                body.querySelector('#calltouchId')?.remove()
            }

        }
    }

    return (
        <>
            <Provider store={store}>
                <PersistGate
                    loading={null}
                    persistor={persistor}
                >
                    <NextNprogress
                        color="#29D"
                        startPosition={0.3}
                        stopDelayMs={200}
                        height="3"
                        options={{easing: 'ease', speed: 500}}
                    />

                    <PageTransition
                        timeout={TIMEOUT}
                        classNames="page-transition"
                        loadingTimeout={{
                            enter: TIMEOUT,
                            exit: 0,
                        }}
                        loadingClassNames="loading-indicator"
                    >
                        <Component {...pageProps} key={router.pathname}/>
                    </PageTransition>
                </PersistGate>
            </Provider>
            <style jsx global>{`
              .page-transition-enter {
                opacity: 0;
                transform: translate3d(-300px, 0, 0);
              }

              .page-transition-enter-active {
                opacity: 1;
                transform: translate3d(0, 0, 0);
                transition: opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms;
              }

              .page-transition-exit {
                opacity: 1;
              }

              .page-transition-exit-active {
                opacity: 0;
                transition: opacity ${TIMEOUT}ms;
              }

              .loading-indicator-appear,
              .loading-indicator-enter {
                opacity: 0;
              }

              .loading-indicator-appear-active,
              .loading-indicator-enter-active {
                opacity: 1;
                transition: opacity ${TIMEOUT}ms;
              }
            `}</style>
        </>
    )
}
