const isServer = typeof window === "undefined";
const apiHost = isServer ? '' : process.env.NEXT_PUBLIC_API_HOST || '';
const apiPort = isServer ? '' : process.env.NEXT_PUBLIC_API_PORT || '';
const apiProto = isServer ? 'http' : process.env.NEXT_PUBLIC_API_PROTO || '';
const apiBasePath = process.env.NEXT_PUBLIC_API_BASE_PATH || '/api/';

const apiBaseUrl = apiHost ? `${apiProto}://${apiHost}${apiPort ? `:${apiPort}` : ''}` : '';
const apiUrl = `${apiBaseUrl}${apiBasePath}`;

module.exports = {
    apiBaseUrl,
    apiUrl,
    isServer,
}