import {appActionTypes} from "../actions/app";

export const appReducer = function (state = {errors: []}, action) {
    switch (action.type) {
        case appActionTypes.SHOW_ERROR:
        case appActionTypes.SHOW_WARNING:
            state.errors.push(action.payload);
            break;
    }
    return state;
};