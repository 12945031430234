import React from 'react'
import { useMemo } from 'react'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { appReducer } from "./reducers/app";
import { catalogReducer } from './reducers/catalog'
import { feedbackReducer } from "./reducers/feedback";
import { snackbarReducer } from "./reducers/snackbar";
import { createWrapper } from "next-redux-wrapper"

let store

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'catalog',
        'feedback',
        'snackbar'
    ],
}

const reducers = combineReducers({
    app: appReducer,
    catalog: catalogReducer,
    feedback: feedbackReducer,
    snackbar: snackbarReducer
})

const persistedReducer = persistReducer(persistConfig, reducers)

function initStore(initialState) {
    return createStore(
        persistedReducer,
        initialState,
        composeWithDevTools(applyMiddleware(thunkMiddleware))
    )
}

export class PersistGateServer extends React.Component {
    render() {
        return this.props.children
    }
}

export const initializeStore = (preloadedState) => {
    let _store = store ?? initStore(preloadedState)

    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState,
        })
        store = undefined
    }

    if (typeof window === 'undefined') {
        return _store
    }

    if (!store) {
        store = _store
    }

    return _store
}

export const wrapper = createWrapper(initializeStore, { debug: false })

export function useStore(initialState) {
    return useMemo(() => initializeStore(initialState), [initialState])
}
