export const types = {
    SET_VIEW_MODE: 'SET_VIEW_MODE',
    SET_FAVORITES: 'SET_FAVORITES',
    REMOVE_FAVORITES: 'REMOVE_FAVORITES',
    CLEAR_FAVORITES: 'CLEAR_FAVORITES',
    SET_COMPARISON: 'SET_COMPARISON',
    REMOVE_COMPARISON: 'REMOVE_COMPARISON',
    CLEAR_COMPARISON: 'CLEAR_COMPARISON',
    SET_SORTING: 'SET_SORTING',
    SET_SORTING_TYPE: 'SET_SORTING_TYPE',
    CLEAR_SORTING: 'CLEAR_SORTING',
    SET_ROOMS: 'SET_ROOMS',
    REMOVE_ROOMS: 'REMOVE_ROOMS',
    SET_AREA: 'SET_AREA',
    SET_PRICE: 'SET_PRICE',
    SET_FLOOR: 'SET_FLOOR',
    SET_FURNISHED: 'SET_FURNISHED',
    SET_EURO: 'SET_EURO',
    SET_BALCONY: 'SET_BALCONY',
    RESET_FILTER: 'RESET_FILTER',
    OPEN_FILTER: 'OPEN_FILTER',
    SET_OFFSET: 'SET_OFFSET',
    SET_WARDROBE: 'SET_WARDROBE',
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    SET_IS_NOT_MANUAL_CHANGE: 'SET_IS_NOT_MANUAL_CHANGE',
    IS_RESET: 'IS_RESET',
    SET_IS_IE: 'SET_IS_IE',
    SORTING_FAVORITES: 'SORTING_FAVORITES',
    SET_DISCOUNT: 'SET_DISCOUNT',
    SET_COUNT: 'SET_COUNT',
};

export const setFavorites = function (action) {
    return {
        type: types.SET_FAVORITES,
        payload: action
    }
};

export const sortingFavorites = function(action) {
    return {
        type: types.SORTING_FAVORITES,
        payload: action
    }
};

export const removeFavorites = function (action) {
    return {
        type: types.REMOVE_FAVORITES,
        payload: action
    }
};

export const setComparison = function (action) {
    return {
        type: types.SET_COMPARISON,
        payload: action
    }
}

export const removeComparison = function (action) {
    return {
        type: types.REMOVE_COMPARISON,
        payload: action
    }
}

export const setSorting = function (action) {
    return {
        type: types.SET_SORTING,
        payload: action
    }
}

export const setSortingType = function (action) {
    return {
        type: types.SET_SORTING_TYPE,
        payload: action
    }
}


export const setRooms = function (action) {
    return {
        payload: action,
        type: types.SET_ROOMS,
    }
};

export const removeRooms = function (action) {
    return {
        payload: action,
        type: types.REMOVE_ROOMS,
    }
};

export const setArea = function (action) {
    return {
        payload: action,
        type: types.SET_AREA,
    }
};

export const setPrice = function (action) {
    return {
        payload: action,
        type: types.SET_PRICE,
    }
};

export const setFloor = function (action) {
    return {
        payload: action,
        type: types.SET_FLOOR,
    }
};

export const setFurnished = function (action) {
    return {
        payload: action,
        type: types.SET_FURNISHED,
    }
};

export const setEuro = function (action) {
    return {
        payload: action,
        type: types.SET_EURO,
    }
};
export const setDiscount = function (action) {
    return {
        payload: action,
        type: types.SET_DISCOUNT,
    }
};
export const setBalcony = function (action) {
    return {
        payload: action,
        type: types.SET_BALCONY,
    }
};
export const setWardrobe = function (action) {
    return {
        payload: action,
        type: types.SET_WARDROBE,
    }
};
export const updateFiltersNotManual = function (action) {
    return {
        payload: action,
        type: types.UPDATE_FILTERS,
    }
};
export const setIsNotManualChange = function (action) {
    return {
        payload: action,
        type: types.SET_IS_NOT_MANUAL_CHANGE,
    }
};
export const setCount = function (action) {
  return {
    payload: action,
    type: types.SET_COUNT,
  }
};








