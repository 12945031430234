export const appActionTypes = {
  SHOW_ERROR: 'SHOW_ERROR',
  SHOW_WARNING: 'SHOW_WARNING',
};

const setError = function(message) {
    return {
        type: appActionTypes.SHOW_ERROR,
        payload: message
    }
};

const setWarning = function(message) {
    return {
        type: appActionTypes.SHOW_WARNING,
        payload: message
    }
};

export default {
    setError,
    setWarning,
}