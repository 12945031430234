import {types} from "../actions/snackbar";

export const initState = {
    open: false,
    title: ''
}

export const snackbarReducer = function (state = initState, action) {

    switch (action.type) {
        case types.SET_SNACKBAR:
            return {
                ...state,
                open: action.payload.open,
                title: action.payload.title
            }
    }
    return state;
};