import {types} from "../actions/feedback";

export const initState = {
    feedback_popup: false,
    info: {}
}

export const feedbackReducer = function (state = initState, action) {

    switch (action.type) {
        case types.SET_FEEDBACK_POPUP:
            return {
                ...state,
                feedback_popup: !state.feedback_popup,
                info: action.payload
            }
    }
    return state;
};