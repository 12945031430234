import { types } from "../actions/catalog";

export const initState = {
    viewMode: 'tile',
    favorites: [],
    comparison: [],
    sorting: '',
    rooms: [],
    area: [],
    price: [],
    floor: [],
    furnished: false,
    euro: false,
    balcony: false,
    open_filter: 'false',
    isNotManualChange: false,
    is_discount_from_api: false,
    count: null
}

export const catalogReducer = function (state = initState, action) {
    switch (action.type) {
        case types.SET_VIEW_MODE:
            return {
                ...state,
                viewMode: action.mode
            }
        case types.SET_FAVORITES:
            if (action.payload?.isLink) {
                return {
                    ...state,
                    favorites: action.payload?.favorites,
                    isNotManualChange: false

                }
            }
            return {
                ...state,
                favorites: [...state.favorites, action.payload],
                isNotManualChange: false

            }
        case types.SORTING_FAVORITES:
            const {direction} = action.payload
            return {
                ...state,
                favorites: [...action.payload.favorites.sort((a, b) => {
                    if (a[action.payload.sorting] > b[action.payload.sorting]) return direction.up
                    if (a[action.payload.sorting] < b[action.payload.sorting]) return direction.down
                })],
            }
        case types.CLEAR_FAVORITES:
            return {
                ...state,
                favorites: [],
                isNotManualChange: false
            }
        case types.REMOVE_FAVORITES:
            return {
                ...state,
                favorites: [...state.favorites.filter(a => a.id !== action.payload.id)],
                isNotManualChange: false
            }
        case types.SET_COMPARISON:
            return {
                ...state,
                comparison: [...state.comparison, action.payload],
                isNotManualChange: false
            }
        case types.REMOVE_COMPARISON:
            return {
                ...state,
                comparison: [...state.comparison.filter(a => a.id !== action.payload.id)],
                isNotManualChange: false
            }
        case types.CLEAR_COMPARISON:
            return {
                ...state,
                comparison: [],
                isNotManualChange: false
            }
        case types.SET_SORTING:
            return {
                ...state,
                sorting: action.payload,
                isNotManualChange: false
            }
        case types.SET_SORTING_TYPE:
            return {
                ...state,
                sortingType: action.payload,
                isNotManualChange: false
            }
        case types.CLEAR_SORTING:
            return {
                ...state,
                sorting: '',
                sortingType: null,
                isNotManualChange: false
            }
        case types.IS_RESET:
            return {
                ...state,
                isReset: action.payload
            }
        case types.RESET_FILTER:
            if (true /*!action.payload*/) return {
                ...state,
                rooms: [],
                area: [],
                price: [],
                floor: [],
                furnished: false,
                euro: false,
                balcony: false,
                wardrobe: false,
                sorting: '',
                isNotManualChange: false,
                is_discount_from_api: false,
            }

            return {
                ...state,
                area: [action.payload.area_min, action.payload.area_max],
                area_max: action.payload.area_max,
                area_min: action.payload.area_min,
                comparison: [],
                completion_date: action.payload.completion_date,
                euroformat: action.payload.euroformat,
                favorites: [],
                five_room: true,
                flats_exists: true,
                floor: [action.payload.floor_min, action.payload.floor_max],
                floor_max: action.payload.floor_max,
                floor_min: action.payload.floor_min,
                four_room: true,
                one_room: true,
                open_filter: "false",
                price: [action.payload.price_min, action.payload.price_max],
                price_max: action.payload.price_max,
                price_min: action.payload.price_min,
                sortingType: true,
                terrace: true,
                three_room: true,
                two_room: true,
                viewMode: "tile",
                rooms: [],
                furnished: false,
                euro: false,
                balcony: false,
                wardrobe: false,
                is_discount_from_api: false,
                sorting: '',
                isNotManualChange: false,
            }
        case types.SET_ROOMS:
            return {
                ...state,
                rooms: [...state.rooms, action.payload],
                isNotManualChange: false
            }
        case types.REMOVE_ROOMS:
            return {
                ...state,
                rooms: [...state.rooms.filter(a => a !== action.payload)],
                isNotManualChange: false
            }
        case types.SET_AREA:
            return {
                ...state,
                area: action.payload,
                isNotManualChange: false
            }
        case types.SET_DISCOUNT:
            return {
                ...state,
                is_discount_from_api: action.payload,
                isNotManualChange: false
            }
        case types.SET_PRICE:
            return {
                ...state,
                price: action.payload,
                isNotManualChange: false
            }
        case types.SET_FLOOR:
            return {
                ...state,
                floor: action.payload,
                isNotManualChange: false
            }
        case types.SET_FURNISHED:
            return {
                ...state,
                furnished: action.payload,
                isNotManualChange: false
            }
        case types.SET_EURO:
            return {
                ...state,
                euro: action.payload,
                isNotManualChange: false
            }
        case types.SET_BALCONY:
            return {
                ...state,
                balcony: action.payload,
                isNotManualChange: false
            }
        case types.SET_WARDROBE:
            return {
                ...state,
                wardrobe: action.payload,
                isNotManualChange: false
            }
        case types.OPEN_FILTER: {
            return {
                ...state,
                open_filter: !state.open_filter
            }
        }
        case types.UPDATE_FILTERS:
            return {
                ...state,
                floor: action.payload?.floor,
                area: action.payload?.area,
                price: action.payload?.price,
                isNotManualChange: true
            }
        case types.SET_IS_NOT_MANUAL_CHANGE:
            return {
                ...state,
                isNotManualChange: action.payload
            }
        case types.SET_IS_IE:
            return {
                ...state,
                isIE: action.payload
            }
      case types.SET_COUNT:
        return {
          ...state,
          count: action.payload
        }
    }
    return state;
};
